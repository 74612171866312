class ApiClient {
  constructor(baseURL = 'https://api.ozenfinance.com') {
    this.baseURL = baseURL;
  }

  async get(path) {
    try {
      const response = await fetch(`${this.baseURL}${path}`, {
        method: 'GET',
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json'
        }
      });

      if (!response.ok) {
        const errorData = await response.json();
        throw new Error(errorData.message || `HTTP error! Status: ${response.status}`);
      }

      return response.json();
    } catch (error) {
      console.error('GET request failed:', error);
      throw error;
    }
  }

  async post(path, data) {
    try {
      const response = await fetch(`${this.baseURL}${path}`, {
        method: 'POST',
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json'
        },
        body: JSON.stringify(data)
      });

      if (!response.ok) {
        const errorData = await response.json();
        throw new Error(errorData.message || `HTTP error! Status: ${response.status}`);
      }

      return response.json();
    } catch (error) {
      console.error('POST request failed:', error);
      throw error;
    }
  }
}

export default ApiClient;