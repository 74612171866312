import ApiClient from './api_client';

class ApiService {
  static cardInfoPath = '/api/v1/public/business-card/';
  static _bodyParam = 'body';
  static cardProductsPath = '/api/v1/public/merchant/card/';

  constructor() {
    this.apiClient = new ApiClient();
  }

  async getCardInfo(code) {
    try {
      console.log('Fetching card info for code:', code);
      const response = await this.apiClient.get(`${ApiService.cardInfoPath}${code}`);
      console.log('Received card info response:', response);
      return response[ApiService._bodyParam];
    } catch (error) {
      console.error('Error fetching card info:', error);
      throw error;
    }
  }

  async getCardProducts(code) {
    try {
        console.log('Fetching card products for code:', code);
        const response = await this.apiClient.get(`${ApiService.cardProductsPath}${code}/products`);
        console.log('Received card products response:', response);

        if (Array.isArray(response)) {
            return response.map(product => ({
                ...product,
                photos: product.medias
                    .filter(media => media.type === 'photo')
                    .map(media => media.url),
                photoUrl: product.medias.find(media => media.type === 'photo')?.url,
                videoUrl: product.medias.find(media => media.type === 'video')?.url
            }));
        } else if (response && Array.isArray(response.data)) {
            return response.data.map(product => ({
                ...product,
                photos: product.medias
                    .filter(media => media.type === 'photo')
                    .map(media => media.url),
                photoUrl: product.medias.find(media => media.type === 'photo')?.url,
                videoUrl: product.medias.find(media => media.type === 'video')?.url
            }));
        } else if (response && typeof response === 'object') {
            const possibleArrays = Object.values(response).filter(Array.isArray);
            if (possibleArrays.length > 0) {
                return possibleArrays[0].map(product => ({
                    ...product,
                    photos: product.medias
                        .filter(media => media.type === 'photo')
                        .map(media => media.url),
                    photoUrl: product.medias.find(media => media.type === 'photo')?.url,
                    videoUrl: product.medias.find(media => media.type === 'video')?.url
                }));
            }
        }

        console.error('Unexpected response structure for card products:', response);
        return [];
    } catch (error) {
        console.error('Error fetching card products:', error);
        throw error;
    }
}

  async getProductById(merchantCode, productId) {
    try {
      console.log(`Fetching product info for merchant ${merchantCode} and product ${productId}`);
      const products = await this.getCardProducts(merchantCode);
      const product = products.find(p => p.id === parseInt(productId));

      if (product) {
        console.log('Found product:', product);
        return product;
      } else {
        console.error(`Product with id ${productId} not found`);
        return null;
      }
    } catch (error) {
      console.error('Error fetching product info:', error);
      throw error;
    }
  }

  async sendSmsCode(countryCode, mobileNumber) {
    try {
      if (!countryCode || !mobileNumber) {
        throw new Error('Некорректный формат номера телефона');
      }

      console.log('Sending SMS code to:', countryCode, mobileNumber);
      const response = await this.apiClient.post('/api/v1/public/merchant/order/auth', {
        countryCode,
        mobileNumber
      });
      
      if (!response?.success) {
        throw new Error(response?.message || 'Ошибка при отправке SMS');
      }

      console.log('SMS code response:', response);
      return response;
    } catch (error) {
      console.error('Error sending SMS code:', error);
      throw error;
    }
  }

  async validateItems(cardCode, items) {
    try {
      const availableProducts = await this.getCardProducts(cardCode);
      return items.filter(item => {
        const product = availableProducts.find(p => p.id === item.id);
        return product && product.available !== false;
      });
    } catch (error) {
      console.error('Error validating items:', error);
      return [];
    }
  }

  async placeOrder(mobile, smsCode, cardCode, items, address, customerName) {
    try {
      // Валидация входных данных
      if (!mobile?.countryCode || !mobile?.mobileNumber) {
        throw new Error('Некорректный формат номера телефона');
      }
      if (!smsCode) {
        throw new Error('SMS код обязателен');
      }
      if (!cardCode) {
        throw new Error('Код карты обязателен');
      }
      if (!items?.length) {
        throw new Error('Корзина пуста');
      }
      if (!address?.street || !address?.house) {
        throw new Error('Адрес доставки обязателен');
      }
      if (!customerName) {
        throw new Error('Имя получателя обязательно');
      }

      // Проверяем валидность товаров
      const validItems = await this.validateItems(cardCode, items);
      if (!validItems.length) {
        throw new Error('Нет доступных товаров в корзине');
      }

      console.log('Placing order with validated data:', {
        mobile,
        smsCode,
        cardCode,
        items: validItems,
        address,
        customerName
      });

      const response = await this.apiClient.post('/api/v1/public/merchant/order', {
        mobile: {
          countryCode: mobile.countryCode,
          mobileNumber: mobile.mobileNumber
        },
        smsCode,
        cardCode,
        items: validItems,
        address: {
          street: address.street,
          house: address.house
        },
        customerName
      });

      if (!response?.success) {
        throw new Error(response?.message || 'Ошибка при оформлении заказа');
      }

      console.log('Order response:', response);
      return response;
    } catch (error) {
      console.error('Error placing order:', error);
      throw error;
    }
  }
}

// Создаем экземпляр ApiService
const apiService = new ApiService();

// Экспортируем созданный экземпляр
export default apiService;