import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { 
    Box, 
    Typography, 
    Button, 
    IconButton, 
    SwipeableDrawer, 
    List, 
    ListItem, 
    ListItemText, 
    ListItemAvatar, 
    Avatar, 
    Divider, 
    TextField, 
    Grid, 
    Alert 
} from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import RemoveIcon from '@mui/icons-material/Remove';
import DeleteIcon from '@mui/icons-material/Delete';
import { IMaskInput } from 'react-imask';
import { useCart } from '../context/CartContext';
import apiService from '../api/api_service';
import OrderConfirmation from './OrderConfirmation';

const PhoneMaskCustom = React.forwardRef(function TextMaskCustom(props, ref) {
    const { onChange, ...other } = props;
    return (
        <IMaskInput
            {...other}
            mask="+7 (000) 000-00-00"
            definitions={{
                '#': /[1-9]/,
            }}
            inputRef={ref}
            onAccept={(value) => onChange({ target: { name: props.name, value } })}
            overwrite
        />
    );
});

const CartModal = ({ isOpen, onClose }) => {
    const { code } = useParams();
    const { cart, products, handleChangeQuantity, getTotalCartPrice, clearCart } = useCart();

    const [name, setName] = useState(() => localStorage.getItem('orderName') || '');
    const [phone, setPhone] = useState(() => localStorage.getItem('orderPhone') || '');
    const [street, setStreet] = useState(() => localStorage.getItem('orderStreet') || '');
    const [house, setHouse] = useState(() => localStorage.getItem('orderHouse') || '');
    const [apartment, setApartment] = useState(() => localStorage.getItem('orderApartment') || '');
    const [isFormValid, setIsFormValid] = useState(false);
    const [smsCode, setSmsCode] = useState('');
    const [isSmsCodeSent, setIsSmsCodeSent] = useState(false);
    const [error, setError] = useState('');
    const [showSuccessAlert, setShowSuccessAlert] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [orderConfirmation, setOrderConfirmation] = useState(null);

    useEffect(() => {
        const isPhoneValid = phone.replace(/[^0-9]/g, '').length === 11;
        setIsFormValid(
            name.trim() !== '' && 
            isPhoneValid && 
            street.trim() !== '' && 
            house.trim() !== '' &&
            Object.keys(cart).length > 0
        );
    }, [name, phone, street, house, cart]);

    useEffect(() => {
        localStorage.setItem('orderName', name);
        localStorage.setItem('orderPhone', phone);
        localStorage.setItem('orderStreet', street);
        localStorage.setItem('orderHouse', house);
        localStorage.setItem('orderApartment', apartment);
    }, [name, phone, street, house, apartment]);

    useEffect(() => {
        if (error) {
            const timer = setTimeout(() => setError(''), 3000);
            return () => clearTimeout(timer);
        }
    }, [error]);

    useEffect(() => {
        if (showSuccessAlert) {
            const timer = setTimeout(() => setShowSuccessAlert(false), 3000);
            return () => clearTimeout(timer);
        }
    }, [showSuccessAlert]);

    const inputProps = {
        style: { height: '45px' }
    };

    const inputLabelProps = {
        sx: {
            top: '50%',
            left: '10px',
            transform: 'translateY(-50%)',
            transition: 'all 0.2s',
            '&.MuiInputLabel-shrink': {
                top: -3,
                left: 0,
                transform: 'translate(14px, -6px) scale(0.75)',
                transition: 'none',
            },
        }
    };

    const formatNumber = (number) => {
        return number.toString().replace(/\B(?=(\d{3})+(?!\d))/g, " ");
    };

    const handleQuantityChange = (productId, change) => {
        handleChangeQuantity(productId, change);
    };

    const handleOrderSubmit = async () => {
        try {
            setError('');
            setIsLoading(true);
            
            if (!isSmsCodeSent) {
                await sendSmsCode();
            } else {
                await placeOrder();
            }
        } catch (err) {
            console.error('Order error:', err);
            setError(err.message || 'Произошла ошибка при оформлении заказа');
        } finally {
            setIsLoading(false);
        }
    };

    const sendSmsCode = async () => {
        try {
            const cleanPhone = phone.replace(/[^0-9]/g, '').slice(1);
            const response = await apiService.sendSmsCode('7', cleanPhone);
            
            if (response.success) {
                setIsSmsCodeSent(true);
                setShowSuccessAlert(true);
            } else {
                throw new Error(response.message || 'Не удалось отправить SMS-код');
            }
        } catch (error) {
            console.error('Error sending SMS code:', error);
            setError('Ошибка при отправке SMS-кода');
            throw error;
        }
    };

    const placeOrder = async () => {
        try {
            const cleanPhone = phone.replace(/[^0-9]/g, '').slice(1);
            
            const validItems = Object.entries(cart)
                .filter(([productId]) => products.find(p => p.id === parseInt(productId, 10)))
                .map(([productId, quantity]) => ({
                    id: parseInt(productId, 10),
                    quantity
                }));

            if (validItems.length === 0) {
                throw new Error('В корзине нет доступных товаров');
            }

            const response = await apiService.placeOrder(
                { countryCode: '7', mobileNumber: cleanPhone },
                smsCode,
                code,
                validItems,
                { street, house },
                name
            );

            if (response && response.success) {
                clearCart();
                setOrderConfirmation({
                    orderId: response.body.id,
                    customerName: name
                });
                setSmsCode('');
                setIsSmsCodeSent(false);
            } else {
                throw new Error(response?.message || 'Не удалось оформить заказ');
            }
        } catch (error) {
            console.error('Error placing order:', error);
            setError(error.message || 'Ошибка при оформлении заказа. Пожалуйста, попробуйте снова.');
            throw error;
        }
    };

    return (
        <SwipeableDrawer
            anchor="bottom"
            open={isOpen}
            onClose={onClose}
            onOpen={() => {}}
            disableSwipeToOpen={true}
            PaperProps={{
                style: {
                    borderTopLeftRadius: '25px',
                    borderTopRightRadius: '25px',
                    height: '90%',
                },
            }}
        >
            {orderConfirmation ? (
                <OrderConfirmation 
                    orderId={orderConfirmation.orderId}
                    customerName={orderConfirmation.customerName}
                    onClose={() => {
                        setOrderConfirmation(null);
                        onClose();
                    }}
                />
            ) : (
                <Box sx={{ display: 'flex', flexDirection: 'column', height: '100%' }}>
                    {error && (
                        <Alert severity="error" sx={{ mb: 2 }}>
                            {error}
                        </Alert>
                    )}
                    {showSuccessAlert && (
                        <Alert severity="success" sx={{ mb: 2 }}>
                            {isSmsCodeSent ? 'SMS-код успешно отправлен' : 'Заказ успешно оформлен'}
                        </Alert>
                    )}
                    <Box sx={{ p: 2, flexGrow: 1, overflowY: 'auto' }}>
                        <Typography variant="h6" sx={{ mb: 1 }}>Корзина</Typography>
                        <Alert 
                            severity="success" 
                            sx={{ 
                                mb: 2, 
                                borderRadius: '15px',
                                backgroundColor: '#e8f5e9',
                                '& .MuiAlert-icon': {
                                    color: '#43a047'
                                },
                                '& .MuiAlert-message': {
                                    color: '#2e7d32',
                                    fontSize: '0.9rem',
                                    lineHeight: '1.4'
                                }
                            }}
                        >
                            🎉 Удобный способ заказа! Ваш заказ поступит напрямую продавцу через приложение Özen. Для вашего комфорта продавец свяжется с вами в WhatsApp, чтобы уточнить детали доставки и предложить удобный способ оплаты.
                        </Alert>
                        {Object.keys(cart).length === 0 ? (
                            <Typography sx={{ px: '15px' }}>Ваша корзина пуста</Typography>
                        ) : (
                            <List sx={{ pt: 0 }}>
                                {Object.entries(cart).map(([productId, quantity], index, array) => {
                                    const product = products.find(p => p.id === parseInt(productId, 10));
                                    if (!product) return null;
                                    
                                    console.log('Product in cart:', product); // Добавим отладочный вывод

                                    return (
                                        <React.Fragment key={productId}>
                                            <ListItem sx={{ py: 2, px: 0 }}>
                                                <ListItemAvatar>
                                                    <Box sx={{ position: 'relative', width: 40, height: 40 }}>
                                                        <Avatar 
                                                            src={product.photoUrl} 
                                                            alt={product.label}
                                                            sx={{ 
                                                                width: '100%', 
                                                                height: '100%'
                                                            }} 
                                                        />
                                                        {product.videoUrl && (
                                                            <Box 
                                                                sx={{
                                                                    position: 'absolute',
                                                                    top: -5,
                                                                    right: -5,
                                                                    width: 20,
                                                                    height: 20,
                                                                    borderRadius: '50%',
                                                                    backgroundColor: 'rgba(0, 0, 0, 0.5)',
                                                                    display: 'flex',
                                                                    alignItems: 'center',
                                                                    justifyContent: 'center',
                                                                    color: 'white',
                                                                    fontSize: '12px'
                                                                }}
                                                            >
                                                                🎥
                                                            </Box>
                                                        )}
                                                    </Box>
                                                </ListItemAvatar>
                                                <ListItemText
                                                    primary={product.label}
                                                    secondary={`₸ ${formatNumber(product.sellPrice)} x ${quantity} = ₸ ${formatNumber(product.sellPrice * quantity)}`}
                                                    primaryTypographyProps={{ fontSize: '0.8rem' }}
                                                />
                                                <Box sx={{ display: 'flex', alignItems: 'center' }}>
                                                    {quantity === 0 ? (
                                                        <IconButton onClick={() => handleQuantityChange(productId, 1)}>
                                                            <AddIcon />
                                                        </IconButton>
                                                    ) : (
                                                        <>
                                                            <IconButton onClick={() => handleQuantityChange(productId, -1)}>
                                                                {quantity === 1 ? <DeleteIcon /> : <RemoveIcon />}
                                                            </IconButton>
                                                            <Typography sx={{ mx: 1 }}>{quantity}</Typography>
                                                            <IconButton onClick={() => handleQuantityChange(productId, 1)}>
                                                                <AddIcon />
                                                            </IconButton>
                                                        </>
                                                    )}
                                                </Box>
                                            </ListItem>
                                            {index < array.length - 1 && (
                                                <Divider sx={{ backgroundColor: 'rgba(0, 0, 0, 0.12)', height: '1px' }} />
                                            )}
                                        </React.Fragment>
                                    );
                                })}
                            </List>
                        )}
                    </Box>
                    <Box sx={{ p: 2, borderTop: '1px solid rgba(0, 0, 0, 0.12)' }}>
                        <Typography variant="h6" sx={{ mb: 2, textAlign: 'left', fontSize: '1rem' }}>
                            К оплате: ₸ {formatNumber(getTotalCartPrice())}
                        </Typography>
                        <Grid container spacing={2}>
                            <Grid item xs={6}>
                                <TextField
                                    fullWidth
                                    label="Имя"
                                    variant="outlined"
                                    value={name}
                                    onChange={(e) => setName(e.target.value)}
                                    InputProps={inputProps}
                                    InputLabelProps={inputLabelProps}
                                    required
                                />
                            </Grid>
                            <Grid item xs={6}>
                                <TextField
                                    fullWidth
                                    label="Телефон"
                                    variant="outlined"
                                    value={phone}
                                    onChange={(e) => setPhone(e.target.value)}
                                    InputProps={{
                                        ...inputProps,
                                        inputComponent: PhoneMaskCustom,
                                    }}
                                    InputLabelProps={inputLabelProps}
                                    required
                                />
                            </Grid>
                        </Grid>
                        <Grid container spacing={2} sx={{ mt: 1 }}>
                            <Grid item xs={6}>
                                <TextField
                                    fullWidth
                                    label="Улица"
                                    variant="outlined"
                                    value={street}
                                    onChange={(e) => setStreet(e.target.value)}
                                    InputProps={inputProps}
                                    InputLabelProps={inputLabelProps}
                                    required
                                />
                            </Grid>
                            <Grid item xs={3}>
                                <TextField
                                    fullWidth
                                    label="Дом"
                                    variant="outlined"
                                    value={house}
                                    onChange={(e) => setHouse(e.target.value)}
                                    InputProps={inputProps}
                                    InputLabelProps={inputLabelProps}
                                    required
                                />
                            </Grid>
                            <Grid item xs={3}>
                                <TextField
                                    fullWidth
                                    label="Квартира"
                                    variant="outlined"
                                    value={apartment}
                                    onChange={(e) => setApartment(e.target.value)}
                                    InputProps={inputProps}
                                    InputLabelProps={inputLabelProps}
                                />
                            </Grid>
                        </Grid>
                        {isSmsCodeSent && (
                            <Grid container spacing={2} sx={{ mt: 1 }}>
                                <Grid item xs={12}>
                                    <TextField
                                        fullWidth
                                        label="SMS код"
                                        variant="outlined"
                                        value={smsCode}
                                        onChange={(e) => setSmsCode(e.target.value)}
                                        InputProps={inputProps}
                                        InputLabelProps={inputLabelProps}
                                        required
                                    />
                                </Grid>
                            </Grid>
                        )}
                        <Button
                            variant="contained"
                            fullWidth
                            disabled={!isFormValid || isLoading || (isSmsCodeSent && !smsCode)}
                            onClick={handleOrderSubmit}
                            sx={{
                                mt: 2,
                                height: '50px',
                                borderRadius: '25px',
                                backgroundColor: 'black',
                                color: 'white',
                                '&:hover': {
                                    backgroundColor: '#333'
                                },
                                '&:disabled': {
                                    backgroundColor: 'rgba(0, 0, 0, 0.12)',
                                    color: 'rgba(0, 0, 0, 0.26)'
                                },
                                position: 'relative',
                                fontSize: '0.85rem',
                                textTransform: 'none'
                            }}
                        >
                            {isLoading ? 'Загрузка...' : (isSmsCodeSent ? 'Подтвердить заказ' : 'Получить код')}
                        </Button>
                    </Box>
                </Box>
            )}
        </SwipeableDrawer>
    );
};

export default CartModal;