import React, { useRef, useState, useCallback, useMemo } from 'react';
import { Card, Typography, Box } from '@mui/material';
import { Swiper, SwiperSlide } from 'swiper/react';
import { useCart } from '../context/CartContext';
import 'swiper/css';

const ProductCard = React.memo(({ product, onOpenModal }) => {
    const videoRef = useRef(null);
    const [activeIndex, setActiveIndex] = useState(0);
    const { cart } = useCart();

    const handleSlideChange = useCallback((swiper) => {
        setActiveIndex(swiper.realIndex);
        const slides = swiper.slides;

        slides.forEach((slide, index) => {
            const video = slide.querySelector('video');
            if (video) {
                if (index === swiper.realIndex) {
                    video.play().catch(e => console.error("Error playing video:", e));
                } else {
                    video.pause();
                    video.currentTime = 0;
                }
            }
        });
    }, []);

    const handleCardClick = useCallback(() => {
        if (product) {
            onOpenModal(product);
        }
    }, [onOpenModal, product]);

    const quantity = useMemo(() => {
        if (!product?.id) return 0;
        const productIdStr = String(product.id);
        return cart[productIdStr] || 0;
    }, [cart, product?.id]);

    if (!product) {
        console.error('Product is null or undefined');
        return <Box>Ошибка: Данные о продукте отсутствуют</Box>;
    }

    const { id, label, measureUnit, sellPrice, medias = [] } = product;

    const photos = medias.filter(media => media.type === 'photo').map(media => media.url);
    const video = medias.find(media => media.type === 'video')?.url;

    const missingProps = [];
    if (!id) missingProps.push('id');
    if (!photos.length) missingProps.push('photos');
    if (!label) missingProps.push('label');
    if (!measureUnit) missingProps.push('measureUnit');
    if (typeof sellPrice === 'undefined') missingProps.push('sellPrice');

    if (missingProps.length > 0) {
        console.error(`Product is missing required properties: ${missingProps.join(', ')}`, product);
        return <Box>Ошибка: Неполные данные о продукте (отсутствуют: {missingProps.join(', ')})</Box>;
    }

    const slidesCount = photos.length + (video ? 1 : 0);

    return (
        <Card
            onClick={handleCardClick}
            sx={{
                width: '100%',
                display: 'flex',
                flexDirection: 'column',
                cursor: 'pointer',
                borderRadius: '15px',
                overflow: 'hidden',
                boxShadow: 'none',
                backgroundColor: 'white',
                height: '100%',
                position: 'relative',
            }}
        >
            {quantity > 0 && (
                <Box
                    sx={{
                        position: 'absolute',
                        top: 8,
                        right: 8,
                        width: '20px',
                        height: '20px',
                        borderRadius: '50%',
                        backgroundColor: 'rgba(0, 0, 0, 0.5)',
                        color: 'white',
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        fontSize: '0.75rem',
                        fontWeight: 'bold',
                        zIndex: 2,
                    }}
                >
                    {quantity}
                </Box>
            )}
            <Box sx={{
                aspectRatio: '1 / 1',
                position: 'relative',
                overflow: 'hidden',
            }}>
                <Swiper
                    spaceBetween={0}
                    slidesPerView={1}
                    loop={slidesCount > 1}
                    onSlideChange={handleSlideChange}
                    style={{ width: '100%', height: '100%' }}
                >
                    {photos.map((photoUrl, index) => (
                        <SwiperSlide key={`photo-${index}`}>
                            <Box sx={{
                                width: '100%',
                                height: '100%',
                                '& img': {
                                    width: '100%',
                                    height: '100%',
                                    objectFit: 'cover',
                                    objectPosition: 'center',
                                },
                            }}>
                                <img
                                    src={photoUrl}
                                    alt={`${label} - фото ${index + 1}`}
                                    onError={(e) => {
                                        console.error(`Error loading image ${index + 1} for product ${id}`);
                                        e.target.src = '/path/to/fallback/image.jpg';
                                    }}
                                />
                            </Box>
                        </SwiperSlide>
                    ))}
                    {video && (
                        <SwiperSlide>
                            <Box sx={{
                                width: '100%',
                                height: '100%',
                                '& video': {
                                    width: '100%',
                                    height: '100%',
                                    objectFit: 'cover',
                                    objectPosition: 'center',
                                },
                            }}>
                                <video
                                    ref={videoRef}
                                    src={video}
                                    muted
                                    loop
                                    playsInline
                                    onError={() => console.error(`Error loading video for product ${id}`)}
                                />
                            </Box>
                        </SwiperSlide>
                    )}
                </Swiper>
                {slidesCount > 1 && (
                    <Box sx={{
                        position: 'absolute',
                        bottom: '10px',
                        left: '50%',
                        transform: 'translateX(-50%)',
                        display: 'flex',
                        justifyContent: 'center',
                        zIndex: 1,
                    }}>
                        {[...Array(slidesCount)].map((_, index) => (
                            <Box
                                key={index}
                                sx={{
                                    width: '6px',
                                    height: '6px',
                                    borderRadius: '50%',
                                    backgroundColor: index === activeIndex ? '#fff' : 'rgba(255, 255, 255, 0.5)',
                                    margin: '0 4px',
                                }}
                            />
                        ))}
                    </Box>
                )}
            </Box>
            <Box sx={{
                padding: (theme) => theme.spacing(1),
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'space-between',
                flexGrow: 1,
            }}>
                <Box>
                    <Typography sx={{
                        fontSize: '0.8rem',
                        lineHeight: 1.2,
                        height: '2.4em',
                        overflow: 'hidden',
                        textOverflow: 'ellipsis',
                        display: '-webkit-box',
                        WebkitLineClamp: 2,
                        WebkitBoxOrient: 'vertical',
                        marginBottom: (theme) => theme.spacing(0.5),
                    }}>
                        {label}
                    </Typography>
                    <Typography variant="body2" sx={{ fontSize: '0.7rem', color: 'rgba(0, 0, 0, 0.6)' }}>
                        {measureUnit}
                    </Typography>
                </Box>
                <Typography variant="body1" sx={{ fontSize: '0.9rem', fontWeight: 'bold', mt: 'auto' }}>
                    ₸{sellPrice.toLocaleString()}
                </Typography>
            </Box>
        </Card>
    );
});

ProductCard.displayName = 'ProductCard';

export default ProductCard;