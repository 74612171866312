import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { 
    Container, 
    Box, 
    Typography, 
    TextField, 
    Button,
    Paper
} from '@mui/material';
import apiService from '../api/api_service';

const Welcome = () => {
    const [merchantCode, setMerchantCode] = useState('');
    const [error, setError] = useState('');
    const [loading, setLoading] = useState(false);
    const navigate = useNavigate();

    const handleSubmit = async (e) => {
        e.preventDefault();
        if (merchantCode.trim()) {
            setLoading(true);
            setError('');
            
            try {
                await apiService.getCardInfo(merchantCode.trim());
                navigate(`/merchant/${merchantCode.trim()}`);
            } catch (error) {
                setError('Торговец с таким кодом не найден');
            } finally {
                setLoading(false);
            }
        }
    };

    return (
        <Container maxWidth="sm">
            <Box
                sx={{
                    minHeight: '100vh',
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'center',
                    alignItems: 'center',
                    py: 4
                }}
            >
                <Paper
                    elevation={0}
                    sx={{
                        p: 4,
                        width: '100%',
                        textAlign: 'center',
                        borderRadius: 2,
                        mb: 4
                    }}
                >
                    <Typography variant="h4" component="h1" gutterBottom>
                        Добро пожаловать в Özen Market
                    </Typography>
                    <Typography variant="body1" color="text.secondary" sx={{ mb: 4 }}>
                        Крупнейший маркетплейс, объединяющий торговцев базаров Казахстана, находится в разработке. 
                        Мы создаем уникальную платформу, которая сделает шопинг на базарах удобным и современным.
                    </Typography>
                </Paper>

                <Paper
                    elevation={0}
                    sx={{
                        p: 4,
                        width: '100%',
                        textAlign: 'center',
                        borderRadius: 2,
                        mb: 4
                    }}
                >
                    <Typography variant="body1" color="text.secondary" sx={{ mb: 4 }}>
                        Если у вас есть идентификатор торговца, введите его ниже.
                    </Typography>
                    
                    <form onSubmit={handleSubmit}>
                        <TextField
                            fullWidth
                            variant="outlined"
                            placeholder="Например: ABC123"
                            value={merchantCode}
                            onChange={(e) => {
                                setMerchantCode(e.target.value);
                                setError('');
                            }}
                            sx={{ mb: 2 }}
                            error={!!error}
                            helperText={error}
                            disabled={loading}
                        />
                        <Button
                            type="submit"
                            variant="contained"
                            fullWidth
                            size="large"
                            disabled={!merchantCode.trim() || loading}
                            sx={{
                                borderRadius: '25px',
                                textTransform: 'none',
                                boxShadow: 'none',
                                height: '50px',
                                bgcolor: 'black',
                                '&:hover': {
                                    bgcolor: '#333'
                                }
                            }}
                        >
                            {loading ? 'Проверка...' : 'Перейти к профилю'}
                        </Button>
                    </form>
                </Paper>

                <Button
                    variant="outlined"
                    href="https://ozenfinance.com"
                    target="_blank"
                    rel="noopener noreferrer"
                    fullWidth
                    sx={{
                        borderRadius: '25px',
                        textTransform: 'none',
                        boxShadow: 'none',
                        height: '50px',
                        color: 'black',
                        borderColor: 'black',
                        '&:hover': {
                            borderColor: '#333',
                            color: '#333',
                            bgcolor: 'transparent'
                        }
                    }}
                >
                    Перейти на сайт Özen Finance
                </Button>
            </Box>
        </Container>
    );
};

export default Welcome; 