import React, { useState, useEffect, useCallback, useRef, useMemo, Suspense } from 'react';
import { useParams, useNavigate, useLocation } from 'react-router-dom';
import { Container, Box, CircularProgress, Link } from '@mui/material';
import debounce from 'lodash/debounce';
import ProfileCard from '../components/ProfileCard';
import TabsComponent from '../components/TabsComponent';
import { useAppService } from '../context/AppServiceContext';
import { useSnackbar } from '../context/SnackbarContext';
import { useCart } from '../context/CartContext';

const ProductModal = React.lazy(() => import('../components/ProductModal'));
const CartModal = React.lazy(() => import('../components/CartModal'));

const Home = () => {
    const { code } = useParams();
    const { showSnackbar } = useSnackbar();
    const [cardInfo, setCardInfo] = useState(null);
    const [loading, setLoading] = useState(true);
    const apiService = useAppService();
    const navigate = useNavigate();
    const location = useLocation();

    const [isProductModalOpen, setIsProductModalOpen] = useState(false);
    const [isCartModalOpen, setIsCartModalOpen] = useState(false);
    const [selectedProduct, setSelectedProduct] = useState(null);

    const {
        cart,
        products,
        setProductsData,
        handleAddToCart,
        handleChangeQuantity,
        getTotalCartPrice,
        clearCart,
        cartItemsCount
    } = useCart();

    const apiServiceRef = useRef(apiService);
    const isInitialMount = useRef(true);

    const debouncedNavigate = useMemo(
        () => debounce((path) => {
            navigate(path, { replace: true });
        }, 300),
        [navigate]
    );

    useEffect(() => {
        apiServiceRef.current = apiService;
        return () => {
            debouncedNavigate.cancel();
        };
    }, [apiService, debouncedNavigate]);

    const fetchCardInfo = useCallback(async () => {
        if (!apiServiceRef.current) {
            console.error('API service is not available');
            showSnackbar('Ошибка при инициализации сервиса', 'error');
            setLoading(false);
            return;
        }

        try {
            const [cardData, productsData] = await Promise.all([
                apiServiceRef.current.getCardInfo(code),
                apiServiceRef.current.getCardProducts(code)
            ]);
            setCardInfo(cardData);
            setProductsData(productsData);
        } catch (error) {
            console.error('API error:', error);
            showSnackbar('Ошибка при загрузке данных', 'error');
        } finally {
            setLoading(false);
        }
    }, [code, showSnackbar, setProductsData]);

    useEffect(() => {
        if (apiServiceRef.current) {
            fetchCardInfo();
        }
    }, [fetchCardInfo]);

    const handleCloseProductModal = useCallback(() => {
        setIsProductModalOpen(false);
        setSelectedProduct(null);
        debouncedNavigate(`/merchant/${code}`);
    }, [debouncedNavigate, code]);

    const handleOpenProductModal = useCallback((product) => {
        setSelectedProduct(product);
        setIsProductModalOpen(true);
        debouncedNavigate(`/merchant/${code}/product/${product.id}`);
    }, [debouncedNavigate, code]);

    useEffect(() => {
        if (isInitialMount.current) {
            isInitialMount.current = false;
            return;
        }

        const pathSegments = location.pathname.split('/');
        const lastSegment = pathSegments[pathSegments.length - 1];
        const productId = parseInt(lastSegment, 10);

        if (!isNaN(productId)) {
            const product = products.find(p => p.id === productId);
            if (product && (!selectedProduct || selectedProduct.id !== product.id)) {
                setSelectedProduct(product);
                setIsProductModalOpen(true);
            } else if (!product) {
                handleCloseProductModal();
            }
        } else if (isProductModalOpen) {
            setIsProductModalOpen(false);
            setSelectedProduct(null);
        }
    }, [location.pathname, products, handleCloseProductModal, selectedProduct, isProductModalOpen]);

    const handleOpenCartModal = useCallback(() => {
        setIsCartModalOpen(true);
    }, []);

    const handleCloseCartModal = useCallback(() => {
        setIsCartModalOpen(false);
    }, []);

    const memoizedTotalCartPrice = useMemo(() => getTotalCartPrice(), [getTotalCartPrice]);

    if (loading) {
        return (
            <Box sx={{ minHeight: '100vh', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                <CircularProgress />
            </Box>
        );
    }

    return (
        <Box sx={{
            minHeight: '100vh',
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            pb: 4,
            position: 'relative',
        }}>
            <HeaderBackground />
            <MainContent
                cardInfo={cardInfo}
                onOpenProductModal={handleOpenProductModal}
                onOpenCartModal={handleOpenCartModal}
                cartItemsCount={cartItemsCount}
                totalCartPrice={memoizedTotalCartPrice}
            />
            <PoweredByLink />
            {isProductModalOpen && selectedProduct && (
                <Suspense fallback={<CircularProgress />}>
                    <ProductModal
                        isOpen={isProductModalOpen}
                        onClose={handleCloseProductModal}
                        product={selectedProduct}
                        merchantCode={code}
                        cart={cart}
                        onAddToCart={handleAddToCart}
                        onChangeQuantity={handleChangeQuantity}
                        totalCartPrice={memoizedTotalCartPrice}
                    />
                </Suspense>
            )}
            <Suspense fallback={<CircularProgress />}>
                <CartModal
                    isOpen={isCartModalOpen}
                    onClose={handleCloseCartModal}
                    cart={cart}
                    products={products}
                    onChangeQuantity={handleChangeQuantity}
                    getTotalCartPrice={getTotalCartPrice}
                    clearCart={clearCart}
                />
            </Suspense>
        </Box>
    );
};

const HeaderBackground = React.memo(() => (
    <Box sx={{
        width: '100%',
        height: '250px',
        backgroundImage: 'url(https://i.ibb.co/LhMwT6f/background.png)',
        backgroundSize: 'cover',
        backgroundPosition: 'center',
        position: 'relative',
    }} />
));

const MainContent = React.memo(({ cardInfo, onOpenProductModal, onOpenCartModal, cartItemsCount, totalCartPrice }) => (
    <>
        <Container sx={{
            position: 'absolute',
            top: '250px',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            display: 'flex',
            justifyContent: 'center',
        }}>
            <ProfileCard cardInfo={cardInfo} />
        </Container>
        <Box sx={{ width: '100%', mt: 23 }}>
            <Container>
                <TabsComponent
                    cardInfo={cardInfo}
                    onOpenProductModal={onOpenProductModal}
                    onOpenCartModal={onOpenCartModal}
                    cartItemsCount={cartItemsCount}
                    totalCartPrice={totalCartPrice}
                />
            </Container>
        </Box>
    </>
));

const PoweredByLink = React.memo(() => (
    <Box sx={{
        position: 'fixed',
        top: '16px',
        right: '8px',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
    }}>
        <Link href="https://ozenfinance.com" target="_blank" rel="noopener" underline="none">
            <Box className="gradient-border-button">
                Powered by Özen
            </Box>
        </Link>
    </Box>
));

HeaderBackground.displayName = 'HeaderBackground';
MainContent.displayName = 'MainContent';
PoweredByLink.displayName = 'PoweredByLink';

export default React.memo(Home);