// CategoryTabs.jsx
import React from 'react';
import { Tabs, Tab, Box } from '@mui/material';
import { styled } from '@mui/material/styles';

const StyledTabs = styled(Tabs)({
    minHeight: 'auto',
    '& .MuiTabs-indicator': {
        backgroundColor: 'black',
        height: '1px',
    },
});

const StyledTab = styled((props) => <Tab disableRipple {...props} />)(({ theme }) => ({
    textTransform: 'none',
    minWidth: 0,
    minHeight: 'auto',
    padding: '8px 16px',
    [theme.breakpoints.up('sm')]: {
        minWidth: 0,
    },
    fontWeight: theme.typography.fontWeightRegular,
    marginRight: theme.spacing(1),
    color: 'rgba(0, 0, 0, 0.85)',
    '&:hover': {
        color: 'rgba(0, 0, 0, 0.6)',
        opacity: 1,
    },
    '&.Mui-selected': {
        color: 'black',
        fontWeight: theme.typography.fontWeightMedium,
    },
    '&.Mui-focusVisible': {
        backgroundColor: '#d1eaff',
    },
}));

const CategoryTabs = ({ categories, selectedCategory, onCategoryChange }) => {
    // Сортируем категории по алфавиту, но оставляем "all" в начале
    const sortedCategories = ['all', ...categories.filter(cat => cat !== 'all').sort()];

    return (
        <Box sx={{ width: '100%' }}>
            <StyledTabs
                value={selectedCategory}
                onChange={(event, newValue) => onCategoryChange(newValue)}
                variant="scrollable"
                scrollButtons="auto"
                aria-label="product categories"
            >
                {sortedCategories.map((category) => (
                    <StyledTab
                        key={category}
                        label={category === 'all' ? 'Все' : category}
                        value={category}
                    />
                ))}
            </StyledTabs>
        </Box>
    );
};

export default CategoryTabs;
