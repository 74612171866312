import React, { useEffect, useState } from 'react';
import { Card, CardContent, Box, Typography, Avatar, Button, Grid } from '@mui/material';
import WhatsAppIcon from '@mui/icons-material/WhatsApp';
import InstagramIcon from '@mui/icons-material/Instagram';
import ShareIcon from '@mui/icons-material/Share';
import { FaStar } from 'react-icons/fa';

const baseUrl = 'https://api.ozenfinance.com';

const ProfileCard = ({ cardInfo }) => {
    console.log('cardInfo:', cardInfo);

    const [shareUrl, setShareUrl] = useState('');

    useEffect(() => {
        if (cardInfo && cardInfo.name) {
            document.title = `Özen Market – ${cardInfo.name}`;
            setShareUrl(window.location.href);
        }
    }, [cardInfo]);

    if (!cardInfo) {
        return <Typography>Загрузка информации о профиле...</Typography>;
    }

    const { name, product, schedule, daysOff, photo, contact, smUrl, reviewCount, gradeMap, city, market } = cardInfo;
    const photoUrl = photo ? `${baseUrl}/${photo}` : "https://via.placeholder.com/80";
    const whatsappUrl = `https://wa.me/${formatPhoneNumber(contact)}`;
    const instagramUsername = smUrl === 'инста' ? 'zuck' : smUrl;
    const instagramUrl = `https://www.instagram.com/${instagramUsername}`;

    const shareText = `Базардан сауда жасау енді бұрынғыдан да оңай! Мен ${name}-дан Özen Market арқылы сатып аламын, оның дүкенін қарап көр: ${shareUrl}`;

    const handleShare = async () => {
        if (navigator.share) {
            try {
                await navigator.share({
                    text: shareText,
                });
            } catch (error) {
                console.error('Error sharing:', error);
            }
        } else {
            console.log('Web Share API not supported');
            // Fallback для браузеров, не поддерживающих Web Share API
            console.log(shareText);
            // Здесь можно добавить код для копирования текста в буфер обмена
            // или открытия нового окна с подготовленным текстом для шеринга
        }
    };

    const { avgRating } = calculateSummary(reviewCount, gradeMap);

    console.log('Город:', city);
    console.log('Базар:', market);

    return (
        <Card sx={styles.card}>
            <CardContent>
                <Box display="flex" flexDirection="column" alignItems="center">
                    <Avatar src={photoUrl} sx={styles.avatar} />
                    <Typography variant="h6" sx={styles.name}>{name}</Typography>
                    <Typography 
                        variant="body2" 
                        color="text.secondary" 
                        sx={{ mb: 2, textAlign: 'center' }}
                    >
                        {`город ${city}, базар ${market}`}
                    </Typography>
                    
                    <InfoItem label="Продает" value={product} />
                    <InfoItem label="График работы" value={schedule} />
                    <InfoItem label="Выходные дни" value={daysOff} />
                    <InfoItem label="Оценка" value={
                        <Box display="flex" alignItems="center">
                            <Typography variant="body2" color="text.secondary" mr={1}>
                                {(avgRating || 0).toFixed(1)
                            }
                            </Typography>
                            <Box display="flex" alignItems="center">
                                {Array(5).fill(0).map((_, index) => (
                                    <FaStar
                                        key={index}
                                        size={16}
                                        style={{
                                            color: index < Math.floor(avgRating || 0) ? '#ffd700' : '#ccc',
                                            marginRight: 4,
                                        }}
                                    />
                                ))}
                            </Box>
                        </Box>
                    } />

                    <SocialButtons
                        whatsappUrl={whatsappUrl}
                        instagramUrl={instagramUrl}
                        onShare={handleShare}
                    />
                </Box>
            </CardContent>
        </Card>
    );
};

const InfoItem = ({ label, value }) => (
    <Typography variant="body2" color="text.secondary" sx={styles.infoItem}>
        <strong>{label}:</strong> {value}
    </Typography>
);

const SocialButtons = ({ whatsappUrl, instagramUrl, onShare }) => (
    <Box mt={2} width="100%">
        <Grid container spacing={1} alignItems="center">
            <Grid item xs={4.8}>
                <SocialButton href={whatsappUrl} icon={<WhatsAppIcon />} label="WhatsApp" color="#25D366" hoverColor="#1DA754" fullWidth />
            </Grid>
            <Grid item xs={4.8}>
                <SocialButton href={instagramUrl} icon={<InstagramIcon />} label="Instagram" color="#000" hoverColor="#444" fullWidth />
            </Grid>
            <Grid item xs={2.4}>
                <Button
                    variant="contained"
                    onClick={onShare}
                    sx={styles.shareButton}
                    fullWidth
                >
                    <ShareIcon sx={styles.shareIcon} />
                </Button>
            </Grid>
        </Grid>
    </Box>
);

const SocialButton = ({ href, icon, label, color, hoverColor, onClick, fullWidth }) => (
    <Button
        variant="contained"
        href={href}
        target="_blank"
        rel="noopener noreferrer"
        startIcon={icon}
        sx={styles.socialButton(color, hoverColor)}
        onClick={onClick}
        fullWidth={fullWidth}
    >
        {label}
    </Button>
);

const formatPhoneNumber = (phoneNumber) => {
    let formattedNumber = phoneNumber.replace(/[^0-9+]/g, '');
    if (formattedNumber.startsWith('+79')) {
        formattedNumber = '+77' + formattedNumber.substring(3);
    }
    return formattedNumber;
};

const calculateSummary = (reviewCount, gradeMap) => {
    if (!reviewCount || !gradeMap) {
        return { totalReviews: 0, ratingsCount: Array(5).fill(0), avgRating: 0 };
    }

    const summary = { totalReviews: reviewCount, ratingsCount: Array(5).fill(0) };
    let totalRating = 0;

    Object.entries(gradeMap).forEach(([grade, count]) => {
        const gradeNumber = parseInt(grade, 10);
        summary.ratingsCount[gradeNumber - 1] = count;
        totalRating += gradeNumber * count;
    });

    summary.avgRating = totalRating / summary.totalReviews || 0;
    return summary;
};

const styles = {
    card: {
        width: '100%',
        margin: 'auto',
        borderRadius: 4,
        backgroundColor: '#fff',
        p: 2,
        mb: 4,
        boxShadow: "none",
        '@media (max-width: 600px)': {
            p: 1,
        }
    },
    avatar: {
        width: 80,
        height: 80,
        marginBottom: 2,
        boxShadow: "none"
    },
    name: {
        fontWeight: 'bold',
        textAlign: 'center',
        width: '100%'
    },
    infoItem: {
        width: '100%',
        textAlign: 'left'
    },
    socialButton: (color, hoverColor) => ({
        backgroundColor: color,
        color: '#fff',
        borderRadius: '25px',
        textTransform: 'none',
        boxShadow: "none",
        '&:hover': {
            backgroundColor: hoverColor,
            boxShadow: "none"
        }
    }),
    shareButton: {
        backgroundColor: '#3B5998',
        color: '#fff',
        borderRadius: '25px',
        textTransform: 'none',
        boxShadow: "none",
        minWidth: 0,
        padding: '6px 0',
        '&:hover': {
            backgroundColor: '#2D4373',
            boxShadow: "none"
        }
    },
    shareIcon: {
        fontSize: '1.5rem'
    }
};

export default ProfileCard;
