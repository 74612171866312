import React from 'react';
import { Box, Typography, Link, Container } from '@mui/material';
import { styled } from '@mui/material/styles';

const StyledFooter = styled(Box)(({ theme }) => ({
    width: '100%',
    padding: '24px 0',
    backgroundColor: '#1a1a1a',
    color: '#fff',
    textAlign: 'center',
    marginTop: 'auto',
    position: 'relative',
    overflow: 'hidden',
    '&::before': {
        content: '""',
        position: 'absolute',
        top: 0,
        left: 0,
        right: 0,
        height: '1px',
        background: 'linear-gradient(90deg, transparent, rgba(255,255,255,0.3), transparent)',
    }
}));

const StyledLink = styled(Link)({
    color: '#fff',
    textDecoration: 'none',
    position: 'relative',
    padding: '2px 0',
    transition: 'all 0.3s ease',
    '&::after': {
        content: '""',
        position: 'absolute',
        bottom: 0,
        left: 0,
        width: '0%',
        height: '1px',
        backgroundColor: '#fff',
        transition: 'width 0.3s ease',
    },
    '&:hover': {
        color: '#fff',
        textDecoration: 'none',
        '&::after': {
            width: '100%',
        },
    },
});

const Footer = () => {
    return (
        <StyledFooter component="footer">
            <Container maxWidth="lg">
                <Box sx={{ 
                    display: 'flex', 
                    flexDirection: { xs: 'column', sm: 'row' },
                    justifyContent: 'center',
                    alignItems: 'center',
                    gap: { xs: 2, sm: 4 }
                }}>
                    <Typography 
                        variant="body2" 
                        sx={{ 
                            opacity: 0.9,
                            fontWeight: 300,
                            letterSpacing: '0.5px'
                        }}
                    >
                        Разработано компанией{' '}
                        <StyledLink 
                            href="https://ozenfinance.com" 
                            target="_blank" 
                            rel="noopener noreferrer"
                            sx={{ 
                                fontWeight: 500,
                                letterSpacing: '0.7px',
                            }}
                        >
                            Özen Finance 🇰🇿
                        </StyledLink>
                    </Typography>
                    <Box 
                        sx={{ 
                            width: '4px', 
                            height: '4px', 
                            bgcolor: 'rgba(255,255,255,0.5)', 
                            borderRadius: '50%',
                            display: { xs: 'none', sm: 'block' }
                        }} 
                    />
                    <Typography 
                        variant="body2" 
                        sx={{ 
                            opacity: 0.9,
                            fontWeight: 300,
                            letterSpacing: '0.5px'
                        }}
                    >
                        Поддержка:{' '}
                        <StyledLink 
                            href="mailto:support@ozenfinance.com"
                            sx={{ 
                                fontWeight: 400,
                                letterSpacing: '0.5px',
                            }}
                        >
                            support@ozenfinance.com
                        </StyledLink>
                    </Typography>
                </Box>
                <Typography 
                    variant="caption" 
                    sx={{ 
                        display: 'block',
                        mt: 2,
                        opacity: 0.6,
                        fontWeight: 300,
                        letterSpacing: '0.3px'
                    }}
                >
                    © {new Date().getFullYear()} Özen Finance, LLC. Все права защищены
                </Typography>
            </Container>
        </StyledFooter>
    );
};

export default Footer;