import React from 'react';
import { Box, Typography, Button } from '@mui/material';
import SentimentVeryDissatisfiedIcon from '@mui/icons-material/SentimentVeryDissatisfied';
import SentimentNeutralIcon from '@mui/icons-material/SentimentNeutral';
import SentimentVerySatisfiedIcon from '@mui/icons-material/SentimentVerySatisfied';

const ReviewConfirmation = ({ rating, customerName, onClose }) => {
    const getContent = () => {
        if (rating <= 2) {
            return {
                icon: <SentimentVeryDissatisfiedIcon sx={{ fontSize: 80, color: '#f44336', mb: 3 }} />,
                message: "Нам очень жаль, что вы остались недовольны",
                description: "Ваш отзыв передан продавцу, и он обязательно примет меры для улучшения качества обслуживания. Спасибо, что помогаете нам развиваться и становиться лучше для вас."
            };
        } else if (rating === 3) {
            return {
                icon: <SentimentNeutralIcon sx={{ fontSize: 80, color: '#fb8c00', mb: 3 }} />,
                message: "Спасибо за ваш честный отзыв",
                description: "Ваш отзыв поможет продавцу улучшить свои услуги. Мы надеемся, что ваш следующий опыт покупки будет еще более приятным. Спасибо за ваше мнение!"
            };
        } else {
            return {
                icon: <SentimentVerySatisfiedIcon sx={{ fontSize: 80, color: '#43a047', mb: 3 }} />,
                message: "Большое спасибо за высокую оценку!",
                description: "Ваш положительный отзыв отправлен продавцу. Это вдохновляет их и нас продолжать радовать вас качественным сервисом. Благодарим за ваше доверие!"
            };
        }
    };

    const content = getContent();

    return (
        <Box 
            sx={{ 
                display: 'flex', 
                flexDirection: 'column', 
                alignItems: 'center',
                justifyContent: 'center',
                p: 4,
                textAlign: 'center',
                height: '100%'
            }}
        >
            {content.icon}
            <Typography variant="h5" sx={{ mb: 2, fontWeight: 500 }}>
                {customerName}, {content.message}
            </Typography>
            <Typography variant="body1" sx={{ mb: 3 }}>
                Вы поставили оценку: <strong>{rating} из 5</strong>
            </Typography>
            <Typography 
                variant="body2" 
                sx={{ 
                    mb: 4,
                    color: 'text.secondary',
                    maxWidth: 400,
                    lineHeight: 1.6
                }}
            >
                {content.description}
            </Typography>
            <Button
                variant="contained"
                onClick={onClose}
                sx={{
                    height: '50px',
                    borderRadius: '25px',
                    backgroundColor: 'black',
                    color: 'white',
                    '&:hover': {
                        backgroundColor: '#333'
                    },
                    width: '200px',
                    fontSize: '0.85rem',
                    textTransform: 'none'
                }}
            >
                Закрыть
            </Button>
        </Box>
    );
};

export default ReviewConfirmation; 