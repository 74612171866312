import React from 'react';
import { Box, Typography } from '@mui/material';
import RatingsSummary from '../components/RatingsSummary';
import Reviews from '../components/Reviews';

const ReviewsPage = ({ cardInfo }) => {
  console.log('ReviewsPage cardInfo:', cardInfo);

  if (!cardInfo) {
    return <LoadingMessage />;
  }

  const { reviewCount = 0, gradeMap = {}, code, reviews = [] } = cardInfo;

  // Проверяем, что gradeMap содержит все необходимые ключи
  const defaultGradeMap = {
    "1": 0,
    "2": 0,
    "3": 0,
    "4": 0,
    "5": 0,
    ...gradeMap
  };

  return (
    <Box sx={styles.container}>
      <RatingsSummary 
        reviewCount={reviewCount} 
        gradeMap={defaultGradeMap} 
        code={code}
      />
      {reviewCount === 0 ? (
        <NoReviewsMessage />
      ) : (
        <Reviews reviews={reviews} />
      )}
    </Box>
  );
};

const LoadingMessage = () => (
  <Box sx={styles.messageContainer}>
    <Typography component="div" variant="body1" sx={styles.message}>
      Загрузка отзывов...
    </Typography>
  </Box>
);

const NoReviewsMessage = () => (
  <Box sx={styles.messageContainer}>
    <Typography component="div" variant="body1" sx={styles.message}>
      Отзывов пока нет. Будьте первым, кто оставит отзыв!
    </Typography>
  </Box>
);

const styles = {
  container: {
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    gap: 0
  },
  messageContainer: {
    width: '100%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    minHeight: 200
  },
  message: {
    textAlign: 'center',
    color: '#666',
    fontSize: '1rem'
  }
};

export default ReviewsPage;