import React from 'react';
import { Box, Typography, Button } from '@mui/material';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';

const OrderConfirmation = ({ orderId, customerName, onClose }) => {
    return (
        <Box 
            sx={{ 
                display: 'flex', 
                flexDirection: 'column', 
                alignItems: 'center',
                justifyContent: 'center',
                p: 4,
                textAlign: 'center',
                height: '100%'
            }}
        >
            <CheckCircleOutlineIcon 
                sx={{ 
                    fontSize: 80, 
                    color: '#43a047',
                    mb: 3
                }} 
            />
            <Typography variant="h5" sx={{ mb: 2, fontWeight: 500 }}>
                {customerName}, ваш заказ принят!
            </Typography>
            <Typography variant="body1" sx={{ mb: 3 }}>
                Номер вашего заказа: <strong>#{orderId}</strong>
            </Typography>
            <Typography 
                variant="body2" 
                sx={{ 
                    mb: 4,
                    color: 'text.secondary',
                    maxWidth: 400,
                    lineHeight: 1.6
                }}
            >
                В ближайшее время продавец свяжется с вами в WhatsApp для уточнения деталей заказа и способа оплаты.
            </Typography>
            <Button
                variant="contained"
                onClick={onClose}
                sx={{
                    height: '50px',
                    borderRadius: '25px',
                    backgroundColor: 'black',
                    color: 'white',
                    '&:hover': {
                        backgroundColor: '#333'
                    },
                    width: '200px',
                    fontSize: '0.85rem',
                    textTransform: 'none'
                }}
            >
                Закрыть
            </Button>
        </Box>
    );
};

export default OrderConfirmation; 