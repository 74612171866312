import React, { useMemo } from 'react';
import { Box, Paper, Typography, Button, LinearProgress } from '@mui/material';
import { FaStar } from 'react-icons/fa';
import { useNavigate } from 'react-router-dom';

const RatingsSummary = React.memo(({ reviewCount, gradeMap, code }) => {
    const navigate = useNavigate();
    
    const summary = useMemo(() => 
        calculateSummary(reviewCount, gradeMap),
        [reviewCount, gradeMap]
    );

    const handleReviewClick = useMemo(() => 
        () => navigate(`/merchant/${code}/leave-review`),
        [navigate, code]
    );
    
    if (reviewCount === undefined || !gradeMap || !code) {
        return null;
    }

    return (
        <Paper sx={styles.paper}>
            <Typography variant="h6" sx={styles.title}>
                Отзывы
            </Typography>
            <AverageRating avgRating={summary.avgRating} totalReviews={summary.totalReviews} />

            <Box sx={styles.contentContainer}>
                <RatingBars summary={summary} />

                <Button
                    variant="outlined"
                    sx={styles.button}
                    onClick={handleReviewClick}
                >
                    Оставить отзыв
                </Button>
            </Box>
        </Paper>
    );
});

const RatingBars = React.memo(({ summary }) => (
    <>
        {[5, 4, 3, 2, 1].map((num) => (
            <RatingBar
                key={num}
                rating={num}
                count={summary.ratingsCount[num - 1]}
                total={summary.totalReviews}
            />
        ))}
    </>
));

const RatingBar = React.memo(({ rating, count, total }) => (
    <Box display="flex" alignItems="center" sx={styles.ratingBar}>
        <Typography component="div" variant="body1" sx={styles.ratingNumber}>
            {rating}
        </Typography>
        <Box sx={styles.progressBarContainer}>
            <LinearProgress
                variant="determinate"
                value={(count / total) * 100 || 0}
                sx={{
                    ...styles.progressBar,
                    '& .MuiLinearProgress-bar': {
                        backgroundColor: '#ffd700',
                    },
                }}
            />
        </Box>
        <Typography component="div" variant="body1" sx={styles.ratingCount}>
            {count}
        </Typography>
    </Box>
));
const AverageRating = React.memo(({ avgRating, totalReviews }) => (
    <Box display="flex" flexDirection="column" alignItems="center" sx={styles.averageRating}>
        <Box display="flex" alignItems="center">
            {Array(5)
                .fill(0)
                .map((_, index) => (
                    <FaStar
                        key={index}
                        size={20}
                        style={{
                            color: index < Math.floor(avgRating) ? '#ffd700' : '#ccc',
                            marginLeft: 4,
                        }}
                    />
                ))}
        </Box>
        <Typography component="div" variant="body1" sx={styles.totalReviews}>
            {totalReviews} {getReviewWord(totalReviews)}
        </Typography>
    </Box>
));

const calculateSummary = (reviewCount, gradeMap) => {
    if (!reviewCount || !gradeMap) {
        return { totalReviews: 0, ratingsCount: Array(5).fill(0), avgRating: 0 };
    }

    const summary = { 
        totalReviews: reviewCount, 
        ratingsCount: Array(5).fill(0) 
    };
    
    let totalRating = 0;

    Object.entries(gradeMap).forEach(([grade, count]) => {
        const gradeNumber = parseInt(grade, 10);
        if (!isNaN(gradeNumber) && gradeNumber >= 1 && gradeNumber <= 5) {
            summary.ratingsCount[gradeNumber - 1] = count;
            totalRating += gradeNumber * count;
        }
    });

    summary.avgRating = summary.totalReviews > 0 ? totalRating / summary.totalReviews : 0;
    return summary;
};

const getReviewWord = (count) => {
    if (!count) return 'отзывов';
    
    const lastDigit = count % 10;
    const secondLastDigit = Math.floor((count % 100) / 10);

    if (secondLastDigit === 1) return 'отзывов';
    if (lastDigit === 1) return 'отзыв';
    if (lastDigit >= 2 && lastDigit <= 4) return 'отзыва';
    return 'отзывов';
};

const styles = {
    paper: {
        textAlign: 'center',
        p: 2,
        backgroundColor: '#FFF',
        borderRadius: 4,
        mb: 2,
        boxShadow: 'none',
    },
    title: { 
        fontWeight: 'bold',
        mb: 2
    },
    ratingBar: { 
        mt: 1, 
        width: '100%' 
    },
    ratingNumber: { 
        minWidth: 20, 
        fontSize: '1rem',
        fontWeight: 'medium'
    },
    progressBarContainer: { 
        width: '80%', 
        mx: 1 
    },
    progressBar: {
        height: 10,
        borderRadius: 5,
        backgroundColor: '#E0E0E0',
    },
    ratingCount: { 
        minWidth: 20, 
        fontSize: '1rem',
        fontWeight: 'medium'
    },
    averageRating: { 
        mt: 2,
        mb: 2
    },
    totalReviews: { 
        mt: 1, 
        fontSize: '1rem',
        color: '#666'
    },
    button: {
        borderColor: '#000',
        mt: 3,
        width: 'calc(100% - 10px)',
        height: '50px',
        borderRadius: '50px',
        color: '#000',
        boxShadow: 'none',
        textTransform: 'none',
        fontSize: '1rem',
        '&:hover': {
            borderColor: '#000',
            backgroundColor: 'rgba(0, 0, 0, 0.04)'
        }
    },
    contentContainer: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        width: '100%',
    },
};

RatingsSummary.displayName = 'RatingsSummary';

export default RatingsSummary;