import React, { useState, useMemo, useEffect, useRef, useCallback } from 'react';
import { useParams, useNavigate, useLocation } from 'react-router-dom';
import { Box, Typography, Grid, Button, Slide, Paper, IconButton, Collapse } from '@mui/material';
import { styled } from '@mui/material/styles';
import AddIcon from '@mui/icons-material/Add';
import RemoveIcon from '@mui/icons-material/Remove';
import debounce from 'lodash/debounce';
import { useProducts } from '../hooks/useProducts';
import ProductCard from '../components/ProductCard';
import ProductModal from '../components/ProductModal';
import CartModal from '../components/CartModal';
import CategoryTabs from '../components/CategoryTabs';
import { useCart } from '../context/CartContext';

const CartButton = styled(Button)(({ theme }) => ({
    position: 'fixed',
    bottom: '10px',
    left: '15px',
    right: '15px',
    height: '50px',
    borderRadius: '25px',
    padding: theme.spacing(1, 3),
    backgroundColor: 'black',
    color: theme.palette.primary.contrastText,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    zIndex: 1000,
    '&:hover': {
        backgroundColor: 'rgba(0, 0, 0, 0.8)',
    },
}));

const CartText = styled(Typography)({
    position: 'absolute',
    left: '50%',
    transform: 'translateX(-50%)',
    textTransform: 'none',
});

const CartPrice = styled(Typography)({
    position: 'absolute',
    right: '25px',
    color: 'rgba(255, 255, 255, 0.7)',
});

const StyledModal = styled(Box)({
    zIndex: 1400,
});

const DeliveryInfoContainer = styled(Paper)(({ theme }) => ({
    margin: `${theme.spacing(2)} 0`,
    borderRadius: '12px',
    padding: theme.spacing(2),
    backgroundColor: '#ffffff',
    boxShadow: 'none'
}));

const DeliveryHeader = styled(Box)({
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    cursor: 'pointer',
    width: '100%'
});

const DeliveryTitle = styled(Typography)(({ theme }) => ({
    color: '#1a1a1a',
    fontWeight: 400,
    fontSize: '0.9rem'
}));

const DeliveryInfoItem = styled(Box)(({ theme }) => ({
    display: 'flex',
    alignItems: 'flex-start',
    gap: '16px',
    marginBottom: theme.spacing(0),
    '&:last-child': {
        marginBottom: 0
    }
}));

const DeliveryLabel = styled(Typography)({
    minWidth: '140px',
    fontWeight: 500,
    color: '#666',
    fontSize: '0.85rem',
});

const DeliveryValue = styled(Typography)({
    color: '#1a1a1a',
    fontSize: '0.85rem',
    flex: 1,
});

const AnimatedCartModal = ({ isOpen, onClose, ...props }) => {
    return (
        <Slide direction="up" in={isOpen} mountOnEnter unmountOnExit>
            <StyledModal>
                <CartModal isOpen={isOpen} onClose={onClose} {...props} />
            </StyledModal>
        </Slide>
    );
};

const CatalogPage = () => {
    const { code } = useParams();
    const { products, loading, error } = useProducts(code);
    const [selectedProduct, setSelectedProduct] = useState(null);
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [isCartModalOpen, setIsCartModalOpen] = useState(false);
    const [selectedCategory, setSelectedCategory] = useState('all');
    const [isDeliveryExpanded, setIsDeliveryExpanded] = useState(false);
    const navigate = useNavigate();
    const location = useLocation();
    const isProcessingRef = useRef(false);
    const { cart, getTotalCartPrice } = useCart();

    const navigateWithReplace = useCallback((path) => {
        navigate(path, { replace: true });
    }, [navigate]);

    const debouncedNavigateRef = useRef(
        debounce((path) => {
            navigateWithReplace(path);
        }, 300)
    );

    useEffect(() => {
        const currentDebounced = debouncedNavigateRef.current;
        return () => {
            currentDebounced.cancel();
        };
    }, []);

    useEffect(() => {
        if (isProcessingRef.current) return;
        isProcessingRef.current = true;

        const pathSegments = location.pathname.split('/');
        const lastSegment = pathSegments[pathSegments.length - 1];
        const productId = parseInt(lastSegment, 10);

        if (!isNaN(productId)) {
            const product = products.find(p => p.id === productId);
            if (product && product.medias?.length > 0) {
                setSelectedProduct(product);
                setIsModalOpen(true);
            } else {
                setIsModalOpen(false);
                setSelectedProduct(null);
                debouncedNavigateRef.current(`/merchant/${code}`);
            }
        } else {
            setIsModalOpen(false);
            setSelectedProduct(null);
        }

        isProcessingRef.current = false;
    }, [location, products, code, navigateWithReplace]);

    const categories = useMemo(() => {
        if (!products) return [];
        const categorySet = new Set(products.map(product => product.category));
        return ['all', ...Array.from(categorySet)];
    }, [products]);

    const filteredProducts = useMemo(() => {
        if (!products) return [];
        return products.filter(product => 
            product.medias?.length > 0 && 
            (selectedCategory === 'all' || product.category === selectedCategory)
        );
    }, [products, selectedCategory]);

    const handleOpenModal = useCallback((product) => {
        if (!product?.medias?.length) return;
        
        setSelectedProduct(product);
        setIsModalOpen(true);
        if (!location.pathname.includes(`/product/${product.id}`)) {
            debouncedNavigateRef.current(`/merchant/${code}/product/${product.id}`);
        }
    }, [code, location.pathname]);

    const handleCloseModal = useCallback(() => {
        setIsModalOpen(false);
        setSelectedProduct(null);
        if (location.pathname.includes('/product/')) {
            debouncedNavigateRef.current(`/merchant/${code}`);
        }
    }, [code, location.pathname]);

    const handleOpenCartModal = useCallback(() => {
        setIsCartModalOpen(true);
    }, []);

    const handleCloseCartModal = useCallback(() => {
        setIsCartModalOpen(false);
    }, []);

    const handleDeliveryClick = useCallback(() => {
        setIsDeliveryExpanded(prev => !prev);
    }, []);

    if (loading) return <Typography variant="body1" sx={{ textAlign: 'center', pt: 2 }}>Загрузка...</Typography>;
    if (error) return <Typography variant="body1" color="error" sx={{ textAlign: 'center', pt: 2 }}>{error}</Typography>;
    if (!products?.length) return <Typography variant="body1" sx={{ textAlign: 'center', pt: 2 }}>Каталог товаров пока пуст.</Typography>;

    return (
        <Box sx={{ width: '100%', mt: 0, pb: 10 }}>
            <DeliveryInfoContainer elevation={0}>
                <DeliveryHeader onClick={handleDeliveryClick}>
                    <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                        <Typography sx={{ fontSize: '1.2rem' }}>
                            🚚
                        </Typography>
                        <DeliveryTitle>
                            Условия доставки
                        </DeliveryTitle>
                    </Box>
                    <IconButton
                        size="small"
                        sx={{ 
                            padding: 0,
                            backgroundColor: 'transparent',
                            '&:hover': {
                                backgroundColor: 'transparent'
                            }
                        }}
                    >
                        {isDeliveryExpanded ? <RemoveIcon /> : <AddIcon />}
                    </IconButton>
                </DeliveryHeader>

                <Collapse in={isDeliveryExpanded}>
                    <Box sx={{ mt: 2 }}>
                        <DeliveryInfoItem>
                            <DeliveryLabel>
                                Способ доставки
                            </DeliveryLabel>
                            <DeliveryValue>
                                Яндекс.Такси
                            </DeliveryValue>
                        </DeliveryInfoItem>

                        <DeliveryInfoItem>
                            <DeliveryLabel>
                                Срок доставки
                            </DeliveryLabel>
                            <DeliveryValue>
                                В течение 3 часов
                            </DeliveryValue>
                        </DeliveryInfoItem>

                        <DeliveryInfoItem>
                            <DeliveryLabel>
                                Стоимость
                            </DeliveryLabel>
                            <DeliveryValue>
                                Уточняется при подтверждении заказа
                            </DeliveryValue>
                        </DeliveryInfoItem>
                    </Box>
                </Collapse>
            </DeliveryInfoContainer>

            <CategoryTabs
                categories={categories}
                selectedCategory={selectedCategory}
                onCategoryChange={setSelectedCategory}
            />

            <Box sx={{ px: 0, mt: 2 }}>
                <Grid container spacing={2}>
                    {filteredProducts.map((product) => (
                        <Grid item key={product.id} xs={4} sm={4} md={3} lg={2}>
                            <ProductCard
                                product={product}
                                onOpenModal={handleOpenModal}
                            />
                        </Grid>
                    ))}
                </Grid>
            </Box>

            {selectedProduct && (
                <StyledModal>
                    <ProductModal
                        isOpen={isModalOpen}
                        onClose={handleCloseModal}
                        product={selectedProduct}
                        merchantCode={code}
                    />
                </StyledModal>
            )}

            {Object.values(cart).reduce((sum, quantity) => sum + quantity, 0) > 0 && (
                <CartButton onClick={handleOpenCartModal}>
                    <CartText variant="button" sx={{ fontWeight: 'normal' }}>
                        Корзина
                    </CartText>
                    <CartPrice variant="body1">
                        ₸{getTotalCartPrice().toLocaleString()}
                    </CartPrice>
                </CartButton>
            )}

            <AnimatedCartModal
                isOpen={isCartModalOpen}
                onClose={handleCloseCartModal}
            />
        </Box>
    );
};

export default React.memo(CatalogPage);