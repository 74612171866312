import React, { useState } from 'react';
import { Routes, Route, useParams, useNavigate, useLocation } from 'react-router-dom';
import { CssBaseline, Box } from '@mui/material'; 
import Home from './pages/Home';
import ReviewForm from './components/ReviewForm';
import ReviewsPage from './pages/ReviewsPage';
import ProductModal from './components/ProductModal';
import Footer from './components/Footer';
import { useProducts } from './hooks/useProducts';
import './App.css';
import { CartProvider } from './context/CartContext';
import Welcome from './pages/Welcome';

// Создаем компонент редиректа
const RedirectExternal = ({ url }) => {
    React.useEffect(() => {
        window.location.replace(url);
    }, [url]);

    return null;
};

// Компонент-обертка для ProductModal
const ProductModalWrapper = () => {
    const { code, id } = useParams();
    const navigate = useNavigate();
    const location = useLocation();
    const [isOpen, setIsOpen] = useState(true);
    const { products, loading, error } = useProducts(code);

    const handleClose = () => {
        setIsOpen(false);
        navigate(`/merchant/${code}${location.search}`);
    };

    if (loading) {
        return <div>Loading...</div>;
    }

    if (error) {
        return <div>Error: {error}</div>;
    }

    const product = products.find(p => String(p.id) === String(id));

    if (!product) {
        return <div>Product not found</div>;
    }

    return (
        <ProductModal
            isOpen={isOpen}
            onClose={handleClose}
            product={product}
            merchantCode={code}
        />
    );
};

const App = () => {
    return (
        <CartProvider>
            <Box
                sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    minHeight: '100vh'
                }}
            >
                <CssBaseline />
                <Routes>
                    <Route path="/" element={<Welcome />} />
                    <Route path="/merchant/:code" element={<Home />}>
                        <Route path="product/:id" element={<ProductModalWrapper />} />
                    </Route>
                    <Route path="/merchant/:code/leave-review" element={<ReviewForm />} />
                    <Route path="/merchant/:code/reviews" element={<ReviewsPage />} />
                </Routes>
                <Footer />
            </Box>
        </CartProvider>
    );
};

export default App;
